<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterHistory">
          <div class="row">
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.changedAtFrom"
                id="filter_changedAtFrom"
                :label="$t('articleHistory.filter.changed_at_from')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-datepicker
                type="date"
                v-model="filter.changedAtTo"
                id="filter_changedAtTo"
                :label="$t('articleHistory.filter.changed_at_to')"
                disable-form-group
              >
              </app-datepicker>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-select
                v-model="filter.changedBy"
                :options="users"
                option-title="username"
                :label="$t('articleHistory.filter.changed_by')"
                id="filter_changedBy"
              >
              </app-select>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input
                v-model="filter.changeType"
                id="filter_changeType"
                :label="$t('articleHistory.filter.change_type')"
              >
              </app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input
                v-model="filter.articleDocId"
                id="filter_articleDocId"
                :label="$t('articleHistory.filter.article_doc_id')"
              >
              </app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input
                v-model="filter.articleId"
                id="filter_articleId"
                :label="$t('articleHistory.filter.article_id')"
              >
              </app-input>
            </div>
            <div class="col-lg-1 col-md-6">
              <app-input
                v-model="filter.id"
                id="filter_id"
                :label="$t('articleHistory.filter.id')"
              >
              </app-input>
            </div>
            <div class="col-lg-2">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('articleHistory.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import Select from '../form/select/Select'
import ArticleHistoryFilter from '../../model/ArticleHistoryFilter'
import Datepicker from '../form/Datepicker'

export default {
  name: 'ArticleHistoryFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(ArticleHistoryFilter)
    }
  },
  computed: {
    users () {
      return this.$store.getters['user/all']
    }
  },
  components: {
    appInput: Input,
    appSelect: Select,
    appDatepicker: Datepicker
  },
  methods: {
    filterHistory () {
      this.$store.commit('articleHistory/setPage', 1)
      this.$store.commit('articleHistory/setFilter', this.filter)
      this.$store.dispatch('articleHistory/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(ArticleHistoryFilter)
      this.$store.commit('articleHistory/setFilter', this.filter)
      this.$store.dispatch('articleHistory/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['articleHistory/filter']
  }
}
</script>

<style lang="scss">

</style>
